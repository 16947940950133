import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { AuthService, TokenService } from '@shared/services';

export const AuthorizeGuard: CanMatchFn = (route) => {
  const tokenService = inject(TokenService);
  const authService = inject(AuthService);

  if (tokenService.token.get()) {
    if (!tokenService.isAuthorized(route.path)) {
      return false;
    }
    if (tokenService.isLoginExpired()) {
      authService.login();
      return false;
    }
    return true;
  }
  return false;
};
